@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

html,
body,
#root {
  height: 100%;
}

* {
  font-family: "Inter", sans-serif;
}

@layer components {
  .shadow {
    box-shadow: 0px 4px 12px rgba(36, 35, 35, 0.05);
  }

  .height-menu {
    height: calc(100% - 80px);
  }

  .width-menu {
    width: calc(100% - 240px);
  }
}
